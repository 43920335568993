import "./RoomBooking.css";
import React from "react";

import { useTranslation, Trans } from "react-i18next";

const rooms192 = {
  "Room 1": "Room1%40bhcbc.org.au",
  "Room 2": "Room2%40bhcbc.org.au",
  "Room 3": "Room3%40bhcbc.org.au",
  "Room 4": "Room4%40bhcbc.org.au",
  "Room 5": "Room5%40bhcbc.org.au",
  "Room 6": "Room6%40bhcbc.org.au",
  "Room 7": "Room7%40bhcbc.org.au",
  "Room 8": "Room8%40bhcbc.org.au",
  "Room 9": "Room9%40bhcbc.org.au",
};

const rooms194 = {
  "Room 1": "room1_194%40bhcbc.org.au",
  "Room 2": "room2_194%40bhcbc.org.au",
  "Room 3": "room3_194%40bhcbc.org.au",
  "Room 4": "room4_194%40bhcbc.org.au",
  "Room 5": "room5_194%40bhcbc.org.au",
};

export default function RoomBooking() {
  const { t, i18n } = useTranslation();

  const [is192BannerOpen, setABannerOpen] = React.useState(false);
  const [is194BannerOpen, setCBannerOpen] = React.useState(false);

  const [currentRoom, setCurrentRoom] = React.useState("");
  const [calendar, setCalendar] = React.useState("");

  React.useEffect(() => {
    const section = document.querySelector("#room-booking-192");

    if (is192BannerOpen) {
      section.scrollIntoView();
    }
  }, [is192BannerOpen]);

  React.useEffect(() => {}, [calendar]);

  React.useEffect(() => {
    const section = document.querySelector("#room-booking-194");

    if (is194BannerOpen) {
      section.scrollIntoView();
    }
  }, [is194BannerOpen]);

  const [isMobile, setMobile] = React.useState(window.innerWidth <= 650);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 650);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // <iframe
  //   className="room_calendar"
  //   src="https://calendar.google.com/calendar/embed?src=room1%40bhcbc.org.au&ctz=Australia%2FSydney&showTitle=0"
  //   style={{ border: 0 }}
  //   frameborder="0"
  //   scrolling="no"
  // ></iframe>;
  function ButtonGrid(roomList) {
    const rooms = Object.keys(roomList);
    const map = rooms.map((room, i) => {
      return (
        <button
          className="button"
          type="button"
          style={{ backgroundColor: currentRoom == room ? "var(--secondary-color)" : undefined, color: currentRoom == room ? "white" : undefined }}
          onClick={() => {
            setCalendar(roomList[room]);
            setCurrentRoom(room);
            const section = document.querySelector("#room-calendar-container");
            section.scrollIntoView();
          }}
        >
          {room}
        </button>
      );
    });

    return map;
  }

  function Booking192() {
    return (
      <>
        <div id="room-booking-192" className="section room-booking-section">
          <h3>
            <Trans i18nKey="rb.a_text" />
          </h3>
        </div>
        <div className="section">
          <h1>192 Stoney Creek Rd</h1>
          <div className="rooms-grid">{ButtonGrid(rooms192)}</div>
          <img src="/images/192_Floor_Plan.jpg" className="image-default-large" style={{ alignsSelfpaddingTop: "1rem", paddingBottom: "1rem" }}></img>
        </div>
        <div className="section" id="room-calendar-container">
          {currentRoom == "" ? <h1>192 Stoney Creek Rd - No room selected</h1> : <h1>192 Stoney Creek Rd - {currentRoom}</h1>}
          <iframe
            className="room-calendar"
            src={`https://calendar.google.com/calendar/embed?src=${calendar}&ctz=Australia%2FSydney&showTitle=0`}
            style={{ border: 0 }}
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </>
    );
  }

  function Booking194() {
    return (
      <>
        <div id="room-booking-194" className="section room-booking-section">
          <h3>
            <Trans i18nKey="rb.a_text" />
          </h3>
        </div>
        <div className="section">
          <h1>194 Stoney Creek Rd</h1>
          <div className="rooms-grid">{ButtonGrid(rooms194)}</div>
          <img src="/images/194_Floor_Plan.jpg" className="image-default-large" style={{ alignsSelfpaddingTop: "1rem", paddingBottom: "1rem" }}></img>
        </div>

        <div className="section" id="room-calendar-container">
          {currentRoom == "" ? <h1>194 Stoney Creek Rd - No room selected</h1> : <h1>194 Stoney Creek Rd - {currentRoom}</h1>}
          <iframe
            className="room-calendar"
            src={`https://calendar.google.com/calendar/embed?src=${calendar}&ctz=Australia%2FSydney&showTitle=0`}
            style={{ border: 0 }}
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </>
    );
  }

  return (
    <div className="container" style={{ gap: "0px" }}>
      <div className="banner" style={{ backgroundPosition: "50% 55%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/room_booking_banner.jpg)" }}>
        <p className="banner-text">{t("rb.banner_text")}</p>
        <p className="banner-subtext">{t("rb.banner_subtext")}</p>
      </div>

      <div className="section" style={{ padding: "2rem" }}>
        <div className="sunday-school-nav-buttons">
          <button
            onClick={() => {
              setABannerOpen(true);
              setCBannerOpen(false);
              setCalendar("");
              setCurrentRoom("");
            }}
            className="button"
          >
            192 Stoney Creek Rd
          </button>
          <button
            onClick={() => {
              setABannerOpen(false);
              setCBannerOpen(true);
              setCalendar("");
              setCurrentRoom("");
            }}
            className="button"
          >
            194 Stoney Creek Rd
          </button>
        </div>
      </div>

      {is192BannerOpen && Booking192()}
      {is194BannerOpen && Booking194()}
    </div>
  );
}
