import "./Home.css";
import { useTranslation, Trans } from "react-i18next";
import ContactForm from "../components/ContactForm";

export default function AboutOurChurch() {
  const [t, i18n] = useTranslation();
  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 55%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/banners/about_our_church_banner.jpg)" }}>
        <p className="banner-subtext">{t("aoc.banner_subtext")}</p>
        <p className="banner-text">{t("aoc.banner_text")}</p>
      </div>
      <div className="section">
        <h1>{t("aoc.a_heading")}</h1>
        <h3> {t("aoc.a_text")}</h3>
      </div>
      <div className="section">
        <h1>{t("aoc.b_heading")}</h1>
        <p>
          <Trans i18nKey="aoc.b_text" />
        </p>
      </div>
      <div className="section">
        <h1>{t("home.f_heading")}</h1>
        <p>{t("home.f_description")}</p>
      </div>
      <div className="form-container">
        <ContactForm />
      </div>
    </div>
  );
}
