import { useTranslation, Trans } from "react-i18next";
import Loader from "../components/Loader";
import React from "react";
import EventCard from "../components/EventCard";

const url = "https://script.google.com/macros/s/AKfycbzKnjvn_gbo_9OCAc8R6uZNXeHmWFLxX0FVbcw5HJ_vjB2p7vcPyUwEhz0gOAPA_p1e/exec";
export default function Events() {
  const { t, i18n } = useTranslation();

  const [eventsData, setEventsData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setEventsData(data);
        setLoading(false);
      })
      .catch((e) => {});
  }, []);

  React.useEffect(() => {}, [eventsData]);

  function Events() {
    const map = Object.keys(eventsData).map((event) => {
      return <EventCard event={eventsData[event]} />;
    });

    return <>{map}</>;
  }

  return (
    <div className="container">
      <div className="banner" style={{ backgroundPosition: "50% 70%", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/images/banners/events_banner.jpg)" }}>
        <p className="banner-text">{t("events.banner_text")}</p>
      </div>
      <div style={{ marginTop: "4rem" }}></div>
      {loading ? <Loader /> : Events()}
    </div>
  );
}
